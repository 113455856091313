<template>
  <div class="casexPc">
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_1.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_2.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_3.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_4.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_5.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 1"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_index_6.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 2"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_about_1.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 2"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_about_2.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 2"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_about_3.jpg"
      alt=""
    />
    <img
      class="bannerImg"
      v-show="tabId === 2"
      src="https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/website/dingdigit/pc_about_4.jpg"
      alt=""
    />
    <div class="hander">
      <div class="log">
        <img src="../../../assets/log.png" alt="" />
        <div>苏州鼎级数字技术有限公司</div>
      </div>
      <div class="tab">
        <div
          v-for="item in tabList"
          :key="item.id"
          class="item"
          @click="handleTab(item.id)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="lis" v-show="tabId === item.id"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-tab">
        <div
          v-for="item in tabList"
          :key="item.id"
          class="item"
          @click="handleTab(item.id)"
        >
          <div class="title" :class="tabId === item.id ? 'active' : ''">
            {{ item.title }}
          </div>
        </div>
      </div>
      <!-- <div>违法和不良信息举报邮箱: djszkf@Dingdigit.com（工作日回复）</div>
      <a href="https://beian.miit.gov.cn/"
        >增值电信业务经营许可证： 苏ICP备2023046652号</a
      >
      <div>网络文化经营许可证： 苏网文 [2020] 5407-171</div>
      <div>地址： 苏州市工业园区苏惠路88号环球财富广场1幢2008室</div> -->
      <div>违法和不良信息举报邮箱: djszkf@Dingdigit.com（工作日回复）</div>
      <a href="https://beian.miit.gov.cn/">备案号： 苏ICP备2023046652号</a>
      <!-- <div>网络文化经营许可证： 苏网文 [2020] 5407-171</div> -->
      <div>地址： 苏州市工业园区苏惠路88号环球财富广场1幢2008室</div>
    </div>
  </div>
</template>

<script>
import { weChatShare } from "../../../utils/wx";
export default {
  data() {
    return {
      // tab选中
      tabId: 1,
      // tab列表
      tabList: [
        {
          id: 1,
          title: "首页",
        },
        {
          id: 2,
          title: "关于我们",
        },
      ],
    };
  },
  created() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.$router.push({
        path: "/digital/index",
      });
      return true;
    }
    this.showShareTip();
  },
  mounted() {},
  methods: {
    // tab切换
    handleTab(id) {
      this.tabId = id;
      window.scrollTo(0, 0);
    },
    // 显示分享提示
    showShareTip() {
      const params = {
        title: "苏州鼎级数字技术有限公司",
        desc: "Digital惦记AI助手和3D人物互动 APP，交一个关心你的“AI朋友”",
        link: window.location.href,
        imgUrl:
          "https://suzhou-digital.oss-cn-hangzhou.aliyuncs.com/public/logo80%2A80.png",
      };
      weChatShare(params);
    },
  },
};
</script>

<style lang="less" scoped>
.casexPc {
  width: 100%;
  min-width: 1200px;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
  .hander {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 101px 139px 0px 139px;

    .log {
      display: flex;
      align-items: center;
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
      img {
        width: 204px;
        margin-right: 57px;
      }
    }
    .tab {
      width: 334px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        .title {
          margin-bottom: 3px;
        }
        .lis {
          width: 45px;
          height: 6px;
          background: #ededed;
          border-radius: 3px;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 376px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 227px;
    left: 0;
    font-size: 45px;
    font-weight: 400;
    color: #ffffff;
    line-height: 94px;
    a {
      color: #ffffff;
    }
    .bottom-tab {
      display: flex;
      justify-content: space-between;
      width: 401px;
      margin-bottom: 100px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        cursor: pointer;
      }
      .title {
        font-size: 45px;
        color: #ffffff;
      }
      .active {
        font-weight: bold;
      }
    }
  }
}
</style>
